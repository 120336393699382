let isLightTheme = false;

$(document).ready(function() {
	isLightTheme = $("#header").hasClass("light-theme");
});

// hamburguesa movil
$("#mainNavBtn").on("click", toggleMobile);

function toggleMobile() {
	toggleBodyOverflow();

	if (isLightTheme) {
		toggleLightTheme();
	}
}

function toggleLightTheme() {
	if ($("#header").hasClass("light-theme")) {
		$("#header").removeClass("light-theme");
	} else {
		$("#header").addClass("light-theme");
	}
}

function toggleBodyOverflow() {
	if ($("body").css("overflow") == "hidden") {
		$("body").css("overflow", "visible");
	} else {
		$("body").css("overflow", "hidden");
	}
}

// scroll cambia class
const nav = document.querySelector('#header');
const scrollThreshold = 100; // Adjust this value to change the scroll threshold

window.addEventListener('scroll', () => {

	// are we at the top?
	if (window.scrollY > scrollThreshold) {
		nav.classList.add('scrolling');
	} else {
		nav.classList.remove('scrolling');
	}

	// progrssBar for services

	let s = $(window).scrollTop(),
	d = $(document).height(),
	c = $(window).height();

	let scrollPercent = (s / (d - c)) * 100;

	$("#progressBar").css("width", scrollPercent + "%");
});

if ($("#header_servicios").length) {
	$("#header_servicios").animate({scrollLeft: $("#header_servicios .active").position().left -100}, 500);
}